import React, { forwardRef } from 'react';
import { useDropzone } from 'react-dropzone'
import {
  StyledDropzone,
  StyledDropzoneContainer,
  StyledDropzoneItems,
  StyledDropzoneLabel
} from './styles';
import { Loader } from '../../../../index';

const DropzoneComponent = forwardRef(
  (
    {
      accept,
      disabled,
      loading,
      onDrop,
      maxSize,
      minSize,
      multiple,
      files,
      children,
      innerLabel,
      className,
      style,
      label,
      ...rest
    },
    ref
  ) => {

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      acceptedFiles
    } = useDropzone({
      accept,
      disabled: (disabled || loading),
      onDrop,
      multiple
    });

    return (
      <StyledDropzone ref={ref}>
        {label && <StyledDropzoneLabel>{label}</StyledDropzoneLabel>}
        <StyledDropzoneContainer {...getRootProps({isDragActive, isDragAccept, isDragReject, loading})}>
          {loading && <Loader/>}
          <input {...getInputProps()} />
          {innerLabel && <p>{innerLabel}</p>}
          {children && <StyledDropzoneItems>{children}</StyledDropzoneItems>}
        </StyledDropzoneContainer>
      </StyledDropzone>
    );
  }
);

export default DropzoneComponent;
