import React from 'react';
import { Container, Box, Loader, Button } from 'shared-ui';
import { useDocumentData } from 'react-recoil-firebase/src/hooks/firestore/useDocument';
import { withFirebase } from 'react-recoil-firebase';
import { navigate, useParams } from '@reach/router';
import { appAdminRoute } from '../../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/fontawesome-pro-regular/faLongArrowLeft';
import WorkoutPlayer from 'web-app/src/components/WorkoutPlayer';
import { useWorkout } from '../../../hooks/useWorkout';

const WorkoutPreview = ({workout}) => (
  <Container>
    <Box padding={{top: 'lg', bottom: 'lg'}}>
      <Box padding={{bottom: 'sm'}}>
        <Button type="accent-5" size="sm" onClick={() => navigate(appAdminRoute(`workouts`))} icon={<FontAwesomeIcon icon={faLongArrowLeft}/>}>Back to workouts</Button>
      </Box>
      <WorkoutPlayer workout={workout}/>
    </Box>
  </Container>
);

// TODO: Also, get the exercises per workout as well... Hide away in a hook.

const WorkoutPreviewPage = () => {
  const { workoutId } = useParams();

  const { data, loading, error } = useWorkout(workoutId);


  //const { data: workout, loading: workoutLoading, error: workoutError } = useDocumentData(`workouts/${paramsWorkoutId}`, firebase.firestore().doc(`workouts/${paramsWorkoutId}`));

  if (loading) {
    return <Loader overlay={false} fixed={true}/>;
  }

  if (error) {
    return error;
  }

  if (!data) {
    return 'No data!';
  }

  return <WorkoutPreview workout={data}/>;
};

export default WorkoutPreviewPage;
