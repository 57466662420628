import styled, { css } from 'styled-components';
import { sizeToStyle } from 'shared-ui/src/utils/utils';
import { spacing, borderRadiusStyle, transitionStyle, borderStyle } from 'shared-ui/src/utils/styles';


export const StyledExerciseCard = styled.div` 
  ${props => transitionStyle(props.theme, ['background'])}
  ${props => borderRadiusStyle(props.theme, 'base')};
  //margin-bottom: 4px;
  ${props => props.attrs.cardOpened && css`
    background: white;
  `}
`;

export const StyledExerciseCardHeader = styled.div` 
  padding: ${props => spacing(props.theme, 'xs')} ${props => spacing(props.theme, 'sm')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledExerciseCardHeaderTitle = styled.div` 
  display: flex;
  align-items: center;
  
  > svg {
    margin-right: ${props => sizeToStyle(props.theme.global.spacing.sm)};
  }
  
  > div {
    &:hover {
      cursor: pointer;
    }
    
    > span {
      user-select: none;
      font-weight: ${props => props.theme.global.font.fontWeight.thick}
    }
  
    > button {
      margin-left: ${props => sizeToStyle(props.theme.global.spacing.sm)};
    }
  }
  
  .status {
    margin-left: ${props => sizeToStyle(props.theme.global.spacing.sm)};
    color: ${props => props.theme.global.colors['text-weak'][props.theme.mode]};
  }
  
`;

export const StyledExerciseCardHeaderToggle = styled.div` 
  
`;

export const StyledExerciseCardBody = styled.div` 
  padding: ${props => spacing(props.theme, 'sm')} ${props => spacing(props.theme, 'sm')};
  ${props => borderStyle(props.theme, 'top')}
`;
