import styled from 'styled-components';

export const StyledUploadedImage = styled.div` 
  width: 75px;
  height: 75px;
  background-color: ${props => props.theme.global.colors['gray-3']};
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  margin: ${props => props.theme.global.spacing.xs};
`;

export const StyledUploadedImageImg = styled.img`
  width: 75px;
  position:absolute;
  top:0;
  left:0;
`;

export const StyledUploadedImageIcon = styled.div`
  position: absolute;
  top:5px;
  z-index: 2;
  font-size: 16px;
  line-height: 20px;
  width: 20px;
  text-align:center;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  color:white;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
  }
`;

export const StyledUploadedImageDelete = styled(StyledUploadedImageIcon)`
  right:5px;
`;

export const StyledUploadedImageView = styled(StyledUploadedImageIcon)`
  left:5px;
`;

export const StyledUploadedImages = styled.div`
  display: flex;
  margin: 0 -${props => props.theme.global.spacing.xs} -${props => props.theme.global.spacing.xs} -${props => props.theme.global.spacing.xs};
`;
