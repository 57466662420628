import React, { useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDocumentData } from 'react-recoil-firebase/src/hooks/firestore/useDocument';
import FirebaseContext from 'react-recoil-firebase/src/context/firebase';
import { useIdentifyRef, useLoadingValue } from 'react-recoil-firebase/src/hooks/firestore/utils';

export const useWorkout = (workoutId, options) => {

  // TODO: Need a way to detect lastUpdated or something. We don't want to cache, if something has changed.

  const { firebase } = useContext(FirebaseContext);
  const idField = options ? options.idField : undefined;
  const { error, loading, reset, setError, setValue, data, snapshot } = useLoadingValue({key: `fullWorkouts/${workoutId}`, idField});
  const ref = useIdentifyRef(workoutId, reset);
  const { data: workout, loading: workoutLoading, error: workoutError } = useDocumentData(`workouts/${workoutId}`, firebase.firestore().doc(`workouts/${workoutId}`));
  const mounted = useRef(false);

  useEffect(() => {
    if (!ref.current) {
      setValue(undefined);
      return null;
    }

    if(workout && !mounted.current) {
      mounted.current = true;

      // Now workout is available, we need to get exercises.
      function fetchData() {
        try {
          if (workout && workout.exercises && workout.exercises.length) {
            const promises = workout.exercises.map(e => firebase.firestore().collection('Exercises').doc(e.exerciseId).get());
            const exercises = [];
            return Promise.all(promises).then((res) => {
              res.forEach((snapshot, i) => {
                if(snapshot.exists) {
                  exercises.push({...workout.exercises[i], data: snapshot.data(), key: uuidv4()});
                }
              });
              return Promise.resolve(exercises);
            }).catch((e) => {
              return Promise.reject(e);
            });
          }
        } catch (e) {
          return Promise.reject(e);
        }
      }

      fetchData().then((data) => {
        setValue({...workout, exercises: data}, false);
      }).catch(setError);

    }
  }, [ref.current, workout]);

  return { data, snapshot, loading, error };
};
