import React, { useState, useEffect, useCallback } from 'react';
import { withFirebase } from 'react-recoil-firebase';
import { Alert, Button, Heading, Box, Text } from 'shared-ui';
import { Form } from 'shared-ui/form';
import { FormItem } from 'shared-ui/formItem';
import { Input } from 'shared-ui/input';
import { Select } from 'shared-ui/select';
import { Checkbox } from 'shared-ui/checkbox';
import isEqual from 'lodash/isEqual';
import { hasErrors } from 'shared-ui/src/utils/form';
import { useForm } from 'react-hook-form';

import {
  EXERCISE_TYPE_OPTIONS,
  FOCUS_OPTIONS,
  LEVEL_OPTIONS, PROPS_OPTIONS,
  SPACE_OPTIONS,
  TARGETS_OPTIONS
} from 'constants/fixedTypes';
import { getMessageString } from '../../../components/FixedTypeMessage/utils';
import { addUpdateExercise, deleteExercise } from './database';
import { useRecoilValue } from 'recoil';
import { authDataState } from 'react-recoil-firebase/src/atoms/auth';
import ExerciseImagesUpload from '../ExerciseImagesUpload';
import { StyledFormActions } from './styles';

const ExerciseForm = ({exercise, firebase, onSave}) => {
  const { id } = exercise || {};
  const [exerciseId, setExerciseId] = useState(id);
  const { user: { uid: userId } } = useRecoilValue(authDataState) || {};
  const [internalExercise, setInternalExercise] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const useFormConfig = (exerciseId) ? {defaultValues: exercise} : {};
  const form = useForm(useFormConfig);
  const { errors, reset } = form;

  if(!isEqual(internalExercise, exercise)) {
    setInternalExercise(exercise);
  }

  useEffect(() => {
    reset(internalExercise ? internalExercise : {});
  }, [internalExercise]);

  const onSubmit = async (formData) => handleUpdate(formData);

  const handleUpdate = useCallback(async (formData) => {
    setLoading(true);
    try {
      await addUpdateExercise(firebase, userId, exerciseId, formData);
      setLoading(false);
      onSave(formData);
    } catch (e) {
      setError(e.message);
      console.log(e.message, e.code);
      setLoading(false);
    }
  }, [firebase, userId, exerciseId]);

  const handleConfirmDelete = useCallback(() => {
    const r = confirm('Are you sure you want to delete this workout?');
    if (r) {
      handleDelete();
    }
  }, []);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteExercise(firebase, userId, exerciseId);
      //setLoading(false);
      onSave(null);
    } catch (e) {
      setError(e.message);
      console.log(e.message, e.code);
      setLoading(false);
    }
  }, [firebase, userId, exerciseId]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      {error && <Alert type="error" message={error} closable onClose={() => setError(null)}/>}

      <Heading level={1} spacing={{marginBottom: 0}}>{exerciseId ? 'Edit' : 'Add'} Exercise</Heading>
      {exerciseId && <Text text={`ID: ${exerciseId}`}/>}

      <FormItem
        name="name"
        label={'Name'}
        rules={{
          required: true
        }}
        render={props => (
          <Input
            size="md"
            type="text"
            placeholder="Name"
            {...props}
          />
        )}
      />

      <FormItem
        name="type"
        label={'Type'}
        rules={{
          required: true
        }}
        render={props => (
          <Select
            options={EXERCISE_TYPE_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Type"
            {...props}
          />
        )}
      />

      <Box spacing={{bottom: 'base'}}>
        <ExerciseImagesUpload
          exercise={exercise}
        />
      </Box>

      <FormItem
        name="level"
        label={'Level'}
        rules={{
          required: true
        }}
        render={props => (
          <Select
            options={LEVEL_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Level"
            {...props}
          />
        )}
      />

      <FormItem
        name="description"
        label={'Description'}
        rules={{
          required: true
        }}
        render={props => (
          <Input
            as="textarea"
            size="md"
            placeholder="Description"
            {...props}
          />
        )}
      />

      <FormItem
        name="focus"
        label={'Focus'}
        rules={{
          required: true
        }}
        render={props => (
          <Select
            options={FOCUS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Focus"
            isMulti={true}
            {...props}
          />
        )}
      />

      <FormItem
        name="targets"
        label={'Targets'}
        rules={{
          required: true
        }}
        render={props => (
          <Select
            options={TARGETS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Targets"
            isMulti={true}
            {...props}
          />
        )}
      />

      <FormItem
        name="space"
        label={'Space'}
        render={props => (
          <Select
            options={SPACE_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Space"
            isMulti={true}
            {...props}
          />
        )}
      />

      <FormItem
        name="props"
        label={'Props'}
        render={props => (
          <Select
            options={PROPS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Props"
            isMulti={true}
            {...props}
          />
        )}
      />

      <FormItem
        name="discrete"
        label={'Discrete'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Is this exercise discrete?"
            {...props}
          />
        )}
      />

      <FormItem
        name="officeOptimized"
        label={'Office Optimized'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Has it been optimized for an office?"
            {...props}
          />
        )}
      />

      <FormItem
        name="smartClothes"
        label={'Smart Clothes'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Can it be performed in smart clothes?"
            {...props}
          />
        )}
      />

      <FormItem
        name="bothSides"
        label={'Both Sides'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Is it for both sides of the body?"
            {...props}
          />
        )}
      />

      <StyledFormActions>
        <Button
          disabled={hasErrors(errors)}
          htmlType="submit"
          type="brand"
          size="lg"
          loading={loading}
        >
          {exerciseId ? 'Save Changes' : 'Add Exercise'}
        </Button>

        {exerciseId && <Button loading={loading} size="lg" onClick={handleConfirmDelete}>Delete Exercise</Button>}
      </StyledFormActions>
    </Form>
  );
};

export default withFirebase(ExerciseForm);
