import PropTypes from 'prop-types';
import Dropzone from './component';
import {
  defaultPropTypes
} from '../propTypes';

Dropzone.defaultProps = {
  accept: 'image/*',
  disabled: false,
  multiple: false,
  onDrop: () => null,
  loading: false,
  innerLabel: 'Drag \'n\' drop some files here, or click to select files',
  files: () => null,
  className: '',
  style: {}
};

Dropzone.propTypes = {
  ...defaultPropTypes
};

export default Dropzone;
