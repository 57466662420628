import * as fixedTypes from 'constants/fixedTypes';
export const scope = 'app.components.FixedTypeMessage';

export default {
  [fixedTypes.LEVEL_EASY] : {
    id: `${scope}.${fixedTypes.LEVEL_EASY}`,
    defaultMessage: 'Easy',
  },
  [fixedTypes.LEVEL_MEDIUM] : {
    id: `${scope}.${fixedTypes.LEVEL_MEDIUM}`,
    defaultMessage: 'Medium',
  },
  [fixedTypes.LEVEL_HARD] : {
    id: `${scope}.${fixedTypes.LEVEL_HARD}`,
    defaultMessage: 'Hard',
  },
  [fixedTypes.LEVEL_SUPER] : {
    id: `${scope}.${fixedTypes.LEVEL_SUPER}`,
    defaultMessage: 'Super',
  },
  [fixedTypes.INTENSITY_EASY] : {
    id: `${scope}.${fixedTypes.INTENSITY_EASY}`,
    defaultMessage: 'Easy',
  },
  [fixedTypes.INTENSITY_MEDIUM] : {
    id: `${scope}.${fixedTypes.INTENSITY_MEDIUM}`,
    defaultMessage: 'Medium',
  },
  [fixedTypes.INTENSITY_HARD] : {
    id: `${scope}.${fixedTypes.INTENSITY_HARD}`,
    defaultMessage: 'Hard',
  },
  [fixedTypes.INTENSITY_SUPER] : {
    id: `${scope}.${fixedTypes.INTENSITY_SUPER}`,
    defaultMessage: 'Super',
  },
  [fixedTypes.SPACE_SITTING] : {
    id: `${scope}.${fixedTypes.SPACE_SITTING}`,
    defaultMessage: 'Sitting',
  },
  [fixedTypes.SPACE_SITTING_SPACE] : {
    id: `${scope}.${fixedTypes.SPACE_SITTING_SPACE}`,
    defaultMessage: 'Sitting (With space)',
  },
  [fixedTypes.SPACE_STANDING] : {
    id: `${scope}.${fixedTypes.SPACE_STANDING}`,
    defaultMessage: 'Standing',
  },
  [fixedTypes.SPACE_STANDING_SPACE] : {
    id: `${scope}.${fixedTypes.SPACE_STANDING_SPACE}`,
    defaultMessage: 'Standing (With Space)',
  },
  [fixedTypes.SPACE_LYING] : {
    id: `${scope}.${fixedTypes.SPACE_LYING}`,
    defaultMessage: 'Lying',
  },
  [fixedTypes.SPACE_WALL] : {
    id: `${scope}.${fixedTypes.SPACE_WALL}`,
    defaultMessage: 'Wall',
  },
  [fixedTypes.SPACE_KNEELING] : {
    id: `${scope}.${fixedTypes.SPACE_KNEELING}`,
    defaultMessage: 'Kneeling',
  },
  [fixedTypes.FOCUS_NECK_HEAD] : {
    id: `${scope}.${fixedTypes.FOCUS_NECK_HEAD}`,
    defaultMessage: 'Neck & Head',
  },
  [fixedTypes.FOCUS_LEGS_BUTT] : {
    id: `${scope}.${fixedTypes.FOCUS_LEGS_BUTT}`,
    defaultMessage: 'Legs & Butt',
  },
  [fixedTypes.FOCUS_NERD_NECK] : {
    id: `${scope}.${fixedTypes.FOCUS_NERD_NECK}`,
    defaultMessage: 'Nerd Neck',
  },
  [fixedTypes.FOCUS_ROUNDED_SHOULDERS] : {
    id: `${scope}.${fixedTypes.FOCUS_ROUNDED_SHOULDERS}`,
    defaultMessage: 'Rounded Shoulders',
  },
  [fixedTypes.FOCUS_HUNCHBACK] : {
    id: `${scope}.${fixedTypes.FOCUS_HUNCHBACK}`,
    defaultMessage: 'Hunchback',
  },
  [fixedTypes.FOCUS_FORWARD_HIP_TILT] : {
    id: `${scope}.${fixedTypes.FOCUS_FORWARD_HIP_TILT}`,
    defaultMessage: 'Forward Hip Tilt',
  },
  [fixedTypes.FOCUS_BACKWARD_HIP_TILT] : {
    id: `${scope}.${fixedTypes.FOCUS_BACKWARD_HIP_TILT}`,
    defaultMessage: 'Backward Hip Tilt',
  },
  [fixedTypes.FOCUS_CARDIO] : {
    id: `${scope}.${fixedTypes.FOCUS_CARDIO}`,
    defaultMessage: 'Cardio',
  },
  [fixedTypes.FOCUS_SHOULDERS_ARMS] : {
    id: `${scope}.${fixedTypes.FOCUS_SHOULDERS_ARMS}`,
    defaultMessage: 'Shoulders & Arms',
  },
  [fixedTypes.FOCUS_CHEST_BACK] : {
    id: `${scope}.${fixedTypes.FOCUS_CHEST_BACK}`,
    defaultMessage: 'Check & Back',
  },
  [fixedTypes.FOCUS_CORE] : {
    id: `${scope}.${fixedTypes.FOCUS_CORE}`,
    defaultMessage: 'Core',
  },
  [fixedTypes.FOCUS_ALL] : {
    id: `${scope}.${fixedTypes.FOCUS_ALL}`,
    defaultMessage: 'All',
  },
  [fixedTypes.TARGETS_NECK] : {
    id: `${scope}.${fixedTypes.TARGETS_NECK}`,
    defaultMessage: 'Neck',
  },
  [fixedTypes.TARGETS_SPINE] : {
    id: `${scope}.${fixedTypes.TARGETS_SPINE}`,
    defaultMessage: 'Spine',
  },
  [fixedTypes.TARGETS_UPPER_BACK] : {
    id: `${scope}.${fixedTypes.TARGETS_UPPER_BACK}`,
    defaultMessage: 'Upper Back',
  },
  [fixedTypes.TARGETS_MID_BACK] : {
    id: `${scope}.${fixedTypes.TARGETS_MID_BACK}`,
    defaultMessage: 'Mid Back',
  },
  [fixedTypes.TARGETS_LOWER_BACK] : {
    id: `${scope}.${fixedTypes.TARGETS_LOWER_BACK}`,
    defaultMessage: 'Lower Back',
  },
  [fixedTypes.TARGETS_ABS] : {
    id: `${scope}.${fixedTypes.TARGETS_ABS}`,
    defaultMessage: 'Abs',
  },
  [fixedTypes.TARGETS_SIDE_BODY] : {
    id: `${scope}.${fixedTypes.TARGETS_SIDE_BODY}`,
    defaultMessage: 'Side Body',
  },
  [fixedTypes.TARGETS_CORE] : {
    id: `${scope}.${fixedTypes.TARGETS_CORE}`,
    defaultMessage: 'Core',
  },
  [fixedTypes.TARGETS_SHOULDERS] : {
    id: `${scope}.${fixedTypes.TARGETS_SHOULDERS}`,
    defaultMessage: 'Shoulders',
  },
  [fixedTypes.TARGETS_CHEST] : {
    id: `${scope}.${fixedTypes.TARGETS_CHEST}`,
    defaultMessage: 'Chest',
  },
  [fixedTypes.TARGETS_HIPS] : {
    id: `${scope}.${fixedTypes.TARGETS_HIPS}`,
    defaultMessage: 'Hips',
  },
  [fixedTypes.TARGETS_HIP_FLEXORS] : {
    id: `${scope}.${fixedTypes.TARGETS_HIP_FLEXORS}`,
    defaultMessage: 'Hip Flexors',
  },
  [fixedTypes.TARGETS_GLUTES] : {
    id: `${scope}.${fixedTypes.TARGETS_GLUTES}`,
    defaultMessage: 'Glutes',
  },
  [fixedTypes.TARGETS_HAMSTRINGS] : {
    id: `${scope}.${fixedTypes.TARGETS_HAMSTRINGS}`,
    defaultMessage: 'Hamstrings',
  },
  [fixedTypes.TARGETS_QUADS] : {
    id: `${scope}.${fixedTypes.TARGETS_QUADS}`,
    defaultMessage: 'Quads',
  },
  [fixedTypes.TARGETS_CALVES] : {
    id: `${scope}.${fixedTypes.TARGETS_CALVES}`,
    defaultMessage: 'Calves',
  },
  [fixedTypes.TARGETS_ANKLES_FEET] : {
    id: `${scope}.${fixedTypes.TARGETS_ANKLES_FEET}`,
    defaultMessage: 'Ankles & Feet',
  },
  [fixedTypes.TARGETS_WRISTS_HANDS] : {
    id: `${scope}.${fixedTypes.TARGETS_WRISTS_HANDS}`,
    defaultMessage: 'Wrist & Hands',
  },
  [fixedTypes.TARGETS_ARMS_ELBOWS] : {
    id: `${scope}.${fixedTypes.TARGETS_ARMS_ELBOWS}`,
    defaultMessage: 'Arms & Elbows',
  },
  [fixedTypes.TARGETS_GROIN] : {
    id: `${scope}.${fixedTypes.TARGETS_GROIN}`,
    defaultMessage: 'Groin',
  },
  [fixedTypes.TARGETS_ADDUCTORS] : {
    id: `${scope}.${fixedTypes.TARGETS_ADDUCTORS}`,
    defaultMessage: 'Adductors',
  },
  [fixedTypes.TARGETS_ABDUCTORS] : {
    id: `${scope}.${fixedTypes.TARGETS_ABDUCTORS}`,
    defaultMessage: 'Abductors',
  },
  [fixedTypes.TARGETS_TRICEPS] : {
    id: `${scope}.${fixedTypes.TARGETS_TRICEPS}`,
    defaultMessage: 'Triceps',
  },
  [fixedTypes.TARGETS_BICEPS] : {
    id: `${scope}.${fixedTypes.TARGETS_BICEPS}`,
    defaultMessage: 'Biceps',
  },
  [fixedTypes.TARGETS_TRAPS] : {
    id: `${scope}.${fixedTypes.TARGETS_TRAPS}`,
    defaultMessage: 'Traps',
  },
  [fixedTypes.EXERCISE_TYPE_EXERCISE] : {
    id: `${scope}.${fixedTypes.EXERCISE_TYPE_EXERCISE}`,
    defaultMessage: 'Exercise',
  },
  [fixedTypes.EXERCISE_TYPE_MOBILITY] : {
    id: `${scope}.${fixedTypes.EXERCISE_TYPE_MOBILITY}`,
    defaultMessage: 'Mobility',
  },
  [fixedTypes.EXERCISE_TYPE_EXERCISE] : {
    id: `${scope}.${fixedTypes.EXERCISE_TYPE_EXERCISE}`,
    defaultMessage: 'Exercise',
  },
  [fixedTypes.EXERCISE_TYPE_STRETCH] : {
    id: `${scope}.${fixedTypes.EXERCISE_TYPE_STRETCH}`,
    defaultMessage: 'Stretch',
  },
  [fixedTypes.EXERCISE_TYPE_ACTIVE_STRETCH] : {
    id: `${scope}.${fixedTypes.EXERCISE_TYPE_ACTIVE_STRETCH}`,
    defaultMessage: 'Active Stretch',
  },
  [fixedTypes.PROPS_TENNIS_BALL] : {
    id: `${scope}.${fixedTypes.PROPS_TENNIS_BALL}`,
    defaultMessage: 'Tennis Ball',
  },
  [fixedTypes.PROPS_CHAIR] : {
    id: `${scope}.${fixedTypes.PROPS_CHAIR}`,
    defaultMessage: 'Chair',
  },
  [fixedTypes.PROPS_PAPER_PACK] : {
    id: `${scope}.${fixedTypes.PROPS_PAPER_PACK}`,
    defaultMessage: 'Paper Pack',
  },
  [fixedTypes.PROPS_DESK] : {
    id: `${scope}.${fixedTypes.PROPS_DESK}`,
    defaultMessage: 'Solid Desk',
  },
  [fixedTypes.PROPS_STEP] : {
    id: `${scope}.${fixedTypes.PROPS_STEP}`,
    defaultMessage: 'Step',
  },
  [fixedTypes.PROPS_STEPS] : {
    id: `${scope}.${fixedTypes.PROPS_STEPS}`,
    defaultMessage: 'Set of Steps',
  },
  [fixedTypes.PROPS_SOFA] : {
    id: `${scope}.${fixedTypes.PROPS_SOFA}`,
    defaultMessage: 'Sofa',
  },
  [fixedTypes.PROPS_WALL] : {
    id: `${scope}.${fixedTypes.PROPS_WALL}`,
    defaultMessage: 'Wall',
  },
  [fixedTypes.PROPS_EXERCISE_BAND] : {
    id: `${scope}.${fixedTypes.PROPS_EXERCISE_BAND}`,
    defaultMessage: 'Exercise Band',
  },
  [fixedTypes.PROPS_DOORWAY] : {
    id: `${scope}.${fixedTypes.PROPS_DOORWAY}`,
    defaultMessage: 'Doorway',
  },
  [fixedTypes.FREQUENCY_30] : {
    id: `${scope}.${fixedTypes.FREQUENCY_30}`,
    defaultMessage: '30 minutes',
  },
  [fixedTypes.FREQUENCY_60] : {
    id: `${scope}.${fixedTypes.FREQUENCY_60}`,
    defaultMessage: '60 minutes',
  },
  [fixedTypes.FREQUENCY_90] : {
    id: `${scope}.${fixedTypes.FREQUENCY_90}`,
    defaultMessage: '90 minutes',
  },
  [fixedTypes.FREQUENCY_120] : {
    id: `${scope}.${fixedTypes.FREQUENCY_120}`,
    defaultMessage: '120 minutes',
  },
  [fixedTypes.FREQUENCY_150] : {
    id: `${scope}.${fixedTypes.FREQUENCY_150}`,
    defaultMessage: '150 minutes',
  },
  [fixedTypes.FREQUENCY_180] : {
    id: `${scope}.${fixedTypes.FREQUENCY_180}`,
    defaultMessage: '180 minutes',
  },
  [fixedTypes.NOTIFICATION_TYPE_PERSISTENT] : {
    id: `${scope}.${fixedTypes.NOTIFICATION_TYPE_PERSISTENT}`,
    defaultMessage: 'Persistent',
  },
  [fixedTypes.NOTIFICATION_TYPE_TRANSIENT] : {
    id: `${scope}.${fixedTypes.NOTIFICATION_TYPE_TRANSIENT}`,
    defaultMessage: 'Occasional',
  },
  [fixedTypes.NOTIFICATION_TYPE_NONE] : {
    id: `${scope}.${fixedTypes.NOTIFICATION_TYPE_NONE}`,
    defaultMessage: 'None',
  },
  [fixedTypes.WORKOUT_EXERCISE_TYPE_TIME] : {
    id: `${scope}.${fixedTypes.WORKOUT_EXERCISE_TYPE_TIME}`,
    defaultMessage: 'Time-based',
  },
  [fixedTypes.WORKOUT_EXERCISE_TYPE_REPS] : {
    id: `${scope}.${fixedTypes.WORKOUT_EXERCISE_TYPE_REPS}`,
    defaultMessage: 'Rep-based',
  }
};
