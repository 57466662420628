import { useEffect } from 'react';
import { useComparatorRef, useLoadingValue } from '../firestore/utils';

export const useDownloadUrl = (key, storageRef, options) => {
  const idField = options ? options.idField : undefined;
  const { error, loading, reset, setError, setValue, data } = useLoadingValue({key, idField});
  const ref = useComparatorRef(storageRef, isEqual, reset);

  useEffect(() => {
    if (!ref.current) {
      setValue(undefined);
      return;
    }
    ref.current
      .getDownloadURL()
      .then(setValue)
      .catch(setError);
  }, [ref.current]);

  return { data, loading, error };
};



const isEqual = (v1, v2) => {
  const bothNull = !v1 && !v2;
  const equal = !!v1 && !!v2 && v1.fullPath === v2.fullPath;
  return bothNull || equal;
};
