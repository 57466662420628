import styled from 'styled-components';

export const StyledWorkoutBuilder = styled.div` 
 padding:24px 24px;
 border:2px dashed ${props => props.theme.global.colors['brand']};
 border-radius: ${props => props.theme.global.borderRadius.sm};
`;

export const StyledWorkoutBuilderForm = styled.div` 
  margin-bottom: 24px;
  max-width: 500px;
`;

export const StyledWorkoutBuilderFormFocus = styled.div` 
  margin-bottom: 16px;
`;

export const StyledWorkoutBuilderFormExercise = styled.div` 
  display: flex;
  button {
    margin-left: 16px;
    min-width: 80px;
  }
`;
