import React, { forwardRef, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { tableStyles, generateTableThemes } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/fontawesome-pro-regular/faChevronDown';

const TableComponent = forwardRef(
  (
    {
      theme,
      ...rest
    },
    ref
  ) => {
    //const tableThemes = useRef(generateTableThemes(theme)); // Run once
    const tableCustomStyles = tableStyles(/*tableThemes.current, */theme); // May need to change based on theme.

    return (
      <DataTable
        ref={ref}
        theme={theme.mode}
        customStyles={tableCustomStyles}
        sortIcon={<FontAwesomeIcon style={{marginLeft: 5}} icon={faChevronDown}/>}
        {...rest}
      />
    );
  }
);

export default TableComponent;
