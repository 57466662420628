import React, { useState, useEffect, useCallback } from 'react';
import { withFirebase } from 'react-recoil-firebase';
import { Alert, Button, Heading, Box, Text } from 'shared-ui';
import { Form } from 'shared-ui/form';
import { FormItem } from 'shared-ui/formItem';
import { Input } from 'shared-ui/input';
import { Select } from 'shared-ui/select';
import { Checkbox } from 'shared-ui/checkbox';
import isEqual from 'lodash/isEqual';
import { hasErrors } from 'shared-ui/src/utils/form';
import { useForm } from 'react-hook-form';

// TODO: Order of focus changes after save. Keep in same order?
//

import {
  FOCUS_OPTIONS,
  LEVEL_OPTIONS,
  PROPS_OPTIONS,
  SPACE_OPTIONS,
  TARGETS_OPTIONS,
  INTENSITY_OPTIONS
} from 'constants/fixedTypes';
import { getMessageString } from '../../../components/FixedTypeMessage/utils';
import { addUpdateWorkout, deleteWorkout } from './database';
import { useRecoilValue } from 'recoil';
import { authDataState } from 'react-recoil-firebase/src/atoms/auth';
import ExerciseImagesUpload from '../ExerciseImagesUpload';
import { StyledFormActions } from './styles';
import WorkoutBuilder from '../WorkoutBuilder';

const WorkoutForm = ({workout, firebase, onSave}) => {
  const { id } = workout || {};
  const [workoutId, setWorkoutId] = useState(id);
  const { user: { uid: userId } } = useRecoilValue(authDataState) || {};
  const [internalWorkout, setInternalWorkout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const useFormConfig = (workoutId) ? {defaultValues: workout} : {};
  const form = useForm(useFormConfig);
  const { errors, reset, trigger, getValues, clearErrors } = form;

  if(!isEqual(internalWorkout, workout)) {
    setInternalWorkout(workout);
  }

  console.log({internalWorkout});

  useEffect(() => {
    reset(internalWorkout ? internalWorkout : {});
  }, [internalWorkout]);

  const onSubmit = async (formData) => (!loading) ? handleUpdate(formData) : null;

  const handleUpdate = useCallback(async (formData) => {
    setLoading(true);
    try {
      await addUpdateWorkout(firebase, userId, workoutId, formData);
      onSave(formData);
    } catch (e) {
      setError(e.message);
      console.log(e.message, e.code);
      setLoading(false);
    }
  }, [firebase, userId, workoutId]);

  const handleConfirmDelete = useCallback(() => {
    const r = confirm('Are you sure you want to delete this workout?');
    if (r) {
      handleDelete();
    }
  }, []);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteWorkout(firebase, userId, workoutId);
      onSave(null);
    } catch (e) {
      setError(e.message);
      console.log(e.message, e.code);
      setLoading(false);
    }
  }, [firebase, userId, workoutId]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      {error && <Alert type="error" message={error} closable onClose={() => setError(null)}/>}

      <Heading level={1} spacing={{marginBottom: 0}}>{workoutId ? 'Edit' : 'Add'} Workout</Heading>
      {workoutId && <Text text={`ID: ${workoutId}`}/>}

      <FormItem
        name="published"
        label={'Published'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Set workout live"
            {...props}
          />
        )}
      />

      <FormItem
        name="name"
        label={'Name'}
        rules={{
          required: true
        }}
        render={props => {
          return (
            <Input
              size="md"
              type="text"
              placeholder="Name"
              {...props}
            />
          )
        }}
      />

      <Box spacing={{bottom: 'base'}}>
        <ExerciseImagesUpload
          workout={workout}
        />
      </Box>

      <Box spacing={{bottom: 'base'}}>
        <WorkoutBuilder
          workout={workout}
        />
      </Box>

      <FormItem
        name="level"
        label={'Level'}
        rules={{
          required: true
        }}
        render={props => (
          <Select
            options={LEVEL_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Level"
            {...props}
          />
        )}
      />

      <FormItem
        name="intensity"
        label={'Intensity'}
        rules={{
          required: true
        }}
        render={props => (
          <Select
            options={INTENSITY_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Level"
            {...props}
          />
        )}
      />

      <FormItem
        name="description"
        label={'Description'}
        rules={{
          required: true
        }}
        render={props => (
          <Input
            as="textarea"
            size="md"
            placeholder="Description"
            {...props}
          />
        )}
      />

      <FormItem
        name="focus"
        label={'Focus'}
        rules={{
          required: true
        }}
        render={props => {
          console.log(props);
          return (
            <Select
              options={FOCUS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
              size="md"
              placeholder="Focus"
              isMulti={true}
              {...props}
            />
          )
        }}
      />

      <FormItem
        name="targets"
        label={'Targets'}
        rules={{
          required: true
        }}
        render={props => (
          <Select
            options={TARGETS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Targets"
            isMulti={true}
            {...props}
          />
        )}
      />

      <FormItem
        name="space"
        label={'Space'}
        render={props => (
          <Select
            options={SPACE_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Space"
            isMulti={true}
            {...props}
          />
        )}
      />

      <FormItem
        name="props"
        label={'Props'}
        render={props => (
          <Select
            options={PROPS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
            size="md"
            placeholder="Props"
            isMulti={true}
            {...props}
          />
        )}
      />

      <FormItem
        name="discrete"
        label={'Discrete'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Is this workout discrete?"
            {...props}
          />
        )}
      />

      <FormItem
        name="officeOptimized"
        label={'Office Optimized'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Has it been optimized for an office?"
            {...props}
          />
        )}
      />

      <FormItem
        name="smartClothes"
        label={'Smart Clothes'}
        render={props => (
          <Checkbox
            size="md"
            labelRight="Can it be performed in smart clothes?"
            {...props}
          />
        )}
      />

      <StyledFormActions>
        <Button
          disabled={hasErrors(errors)}
          htmlType="submit"
          type="brand"
          size="lg"
          loading={loading}
        >
          {workoutId ? 'Save Changes' : 'Add Workout'}
        </Button>

        {workoutId && <Button loading={loading} size="lg" onClick={handleConfirmDelete}>Delete Workout</Button>}
      </StyledFormActions>
    </Form>
  );
};

WorkoutForm.defaultProps = {
  onSave: () => null
};

export default withFirebase(WorkoutForm);
