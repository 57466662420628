import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FIXED_TYPE_COLORS, FOCUS_OPTIONS, LEVEL_OPTIONS, EXERCISE_TYPE_OPTIONS } from 'constants/fixedTypes'
import { withFirebase } from 'react-recoil-firebase';
import { useCollectionData } from 'react-recoil-firebase/src/hooks/firestore/useCollection';
import FixedTypeMessage from 'web-app/src/components/FixedTypeMessage';
import { Tag, Button, Loader } from 'shared-ui';
import { Input } from 'shared-ui/input';
import { Select } from 'shared-ui/select';
import { Table } from 'shared-ui/table';
import { faCheck } from '@fortawesome/fontawesome-pro-regular/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-pro-regular/faTimes';
import { getMessageString } from '../../../components/FixedTypeMessage/utils';
import { useDebounce } from 'shared-ui/src/hooks/delay';
import { StyledAddButton } from './styles';
import { navigate } from '@reach/router';
import { appAdminRoute } from '../../../constants/routes';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    width: '220px',
    sortable: true,
  },
  {
    name: 'Focus',
    selector: 'focus',
    sortable: true,
    width: '400px',
    allowOverflow: false,
    //minWidth: 200,
    cell: ({focus}) => {
      return focus.map(item => <Tag color={FIXED_TYPE_COLORS[item]} key={item}><FixedTypeMessage message={item}/></Tag>)
    },
  },
  {
    name: 'Targets',
    selector: 'targets',
    width: '350px',
    sortable: true,
    cell: ({targets}) => {
      if(!targets) return ``;
      return targets.map((target) => getMessageString(target)).join(', ');
    },
  },
  {
    name: 'Props',
    selector: 'props',
    sortable: true,
    cell: ({props}) => {
      if(!props) return ``;
      return props.map((prop) => getMessageString(prop)).join(', ');
    },
  },
  {
    name: 'Level',
    selector: 'level',
    sortable: true,
    cell: ({level}) => {
      if(!level) return ``;
      return <Tag color={FIXED_TYPE_COLORS[level]}><FixedTypeMessage message={level} /></Tag>;
    },
  },
  {
    name: 'Intensity',
    selector: 'intensity',
    sortable: true,
    cell: ({intensity}) => {
      if(!intensity) return ``;
      return <Tag color={FIXED_TYPE_COLORS[intensity]}><FixedTypeMessage message={intensity} /></Tag>;
    },
  },
  {
    name: 'Discrete',
    selector: 'discrete',
    sortable: true,
    cell: ({discrete}) => (discrete) ? <FontAwesomeIcon icon={faCheck}/> : ``
  },
  {
    name: 'Office',
    selector: 'officeOptimized',
    sortable: true,
    cell: ({officeOptimized}) => (officeOptimized) ? <FontAwesomeIcon icon={faCheck}/> : ``
  }
];

const FilterComponent = ({
   filterText,
   filterFocus,
   filterLevel,
   onTextFilter,
   onFocusFilter,
   onLevelFilter,
   onClear
}) => {
  const [internalFilterText, setInternalFilterText] = useState(filterText);
  const debouncedFilterText = useDebounce(internalFilterText, 500);

  const internalOnClear = () => {
    onClear();
    setInternalFilterText('');
  };

  useEffect(() => {
    onTextFilter(debouncedFilterText);
  },[debouncedFilterText]);

  return (
    <>
      <Input
        wrapperStyle={{marginRight: 8}}
        width={160}
        id="search"
        size="sm"
        type="text"
        placeholder="Name"
        value={internalFilterText}
        onChange={(e) => setInternalFilterText(e.target.value)}
      />
      <Select
        options={LEVEL_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
        wrapperStyle={{marginRight: 8}}
        width={160}
        size="sm"
        placeholder="Level"
        value={filterLevel}
        onChange={onLevelFilter}
      />
      <Select
        options={FOCUS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
        wrapperStyle={{marginRight: 8}}
        width={320}
        size="sm"
        placeholder="Focus"
        isMulti={true}
        multiLabelsWrapped={false}
        defaultValue={[]}
        value={filterFocus}
        onChange={onFocusFilter}
      />
      <Button
        size="sm"
        type="brand"
        onClick={internalOnClear}
        icon={<FontAwesomeIcon icon={faTimes}/>}
      >
        Clear
      </Button>
    </>
  );
};

const WorkoutsTable = ({firebase}) => {
  const [filterText, setFilterText] = useState('');
  const [filterFocus, setFilterFocus] = useState([]);
  const [filterLevel, setFilterLevel] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const { data, loading, error } = useCollectionData('workouts',
    firebase.firestore().collection('workouts')
  );

  useEffect(() => {
    let filteredData = (data) ? data : [];
    filteredData = filteredData
      .filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
      .filter(item => (filterFocus && filterFocus.length) ? filterFocus.every(option => item.focus && item.focus.includes(option)) : true)
      .filter(item => (item.level && filterLevel) ? item.level === filterLevel : true);
    setFilteredData(filteredData);
  }, [data, filterText, filterFocus, filterLevel]);

  const handleRowClicked = (row) => {
    navigate(appAdminRoute(`workouts/${row.id}`));
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) setFilterText('');
      if(filterFocus && filterFocus.length) setFilterFocus([]);
      if(filterLevel && filterLevel.length) setFilterLevel(null);

      setResetPaginationToggle(!resetPaginationToggle);
    };

    return (
      <>
        <StyledAddButton type="brand" size="sm" onClick={() => navigate(appAdminRoute(`workouts/new`))}>Add Workout</StyledAddButton>
        <FilterComponent
          onClear={handleClear}
          filterText={filterText}
          filterFocus={filterFocus}
          filterLevel={filterLevel}
          onTextFilter={value => setFilterText(value)}
          onFocusFilter={value => setFilterFocus(value)}
          onLevelFilter={value => setFilterLevel(value)}
        />
      </>
    );
  }, [filterText, filterFocus, filterLevel, resetPaginationToggle]);

  if(loading) return <Loader overlay={false} fixed={true}/>;

  if(error) {
    return 'Error...';
  }

  if(data) {
    return (
      <div>
        <Table
          title="Workouts"
          columns={columns}
          data={filteredData}
          onRowClicked={handleRowClicked}
          pointerOnHover={true}
          highlightOnHover={true}
          pagination
          paginationPerPage={50}
          paginationResetDefaultPage={resetPaginationToggle}
          noHeader={true}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
    );
  }

  return null
};

export default withFirebase(WorkoutsTable);
