import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useIsEqualRef, useLoadingValue } from './utils';
import { firestoreDataState } from '../../atoms/firestore';

export const useCollection = (key, query, options) => {
  const idField = options ? options.idField : undefined;
  const { error, loading, reset, setError, setValue, data, snapshot } = useLoadingValue({key, idField});
  const ref = useIsEqualRef(query, reset);

  useEffect(() => {
    if (!ref.current) {
      setValue(undefined);
      return;
    }

    const listener = options && options.snapshotListenOptions
      ? ref.current.onSnapshot(options.snapshotListenOptions, setValue, setError)
      : ref.current.onSnapshot(setValue, setError);
    return () => {
      listener();
    };
  }, [ref.current]);

  return { data, snapshot, loading, error };
};

export const useCollectionData = (key, query, options) => {
  useCollection(key, query, options);
  const { error, loading, data } = useRecoilValue(firestoreDataState(key));
  return { data, loading, error };
};
