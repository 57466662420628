import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Select } from 'react-functional-select';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  applyThemeStyles,
  StyledSelectWrapper
} from './styles';
import { faChevronDown } from '@fortawesome/fontawesome-pro-regular/faChevronDown';
import { useFormContext } from 'react-hook-form';

const SelectCaret = ({menuOpen}) => {
  return <FontAwesomeIcon icon={faChevronDown} rotation={(menuOpen) ? 180 : 0}/>;
};

const SelectComponent = forwardRef(
  (
    {
      theme,
      className,
      loading,
      disabled,
      multiLabelsWrapped,
      style,
      wrapperStyle,
      size,
      value,
      defaultValue,
      placeholder,
      elevation,
      width,
      onChange,
      onFocus,
      onBlur,
      isClearable,
      isMulti,
      getOptionLabel,
      getOptionValue,
      getOptionByValue,
      options,
      name,
      acceptValueOnly,
      ...rest
    },
    ref
  ) => {

    const form = useFormContext();
    const { formState, trigger, errors } = form || {};

    const mounted = useRef(false);
    const selectRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const attrs = {
      hasPlaceholder: (placeholder),
      loading,
      multiLabelsWrapped,
      size,
      menuOpen
    };
    const selectThemeConfig = useMemo(() => applyThemeStyles(theme, attrs), [theme.mode, attrs]);
    const [internalValue, setInternalValue] = useState(defaultValue !== undefined ? defaultValue : isMulti ? [] : null);

    const handleInternalOnChange = useCallback((option) => {
      if(acceptValueOnly) {
        if(Array.isArray(option)) {
          option = option.map(o => getOptionValue(o));
        } else {
          option = getOptionValue(option);
        }
      }

      setInternalValue(option);
      onChange(option);
    }, []);

    useEffect(() => {
      if(errors && errors[name]) {
        form.trigger(name);
      }
    }, [internalValue]);

    useEffect(() => {
      if(!isEqual(internalValue, value)) {
        let v = value;
        if(acceptValueOnly) {
          if(Array.isArray(value)) {
            v = value.map(o => getOptionByValue(options, o));
          } else {
            v = getOptionByValue(options, value);
          }
        }

        selectRef.current.setValue(v);
      }
    }, [selectRef.current]);

    return (
      <StyledSelectWrapper attrs={attrs} style={{width, ...wrapperStyle}}>
        <Select
          ref={selectRef}
          placeholder={placeholder}
          isInvalid={false}
          options={options}
          initialValue={defaultValue}
          addClassNames={true}
          isDisabled={disabled}
          isLoading={loading}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          isClearable={isClearable}
          isMulti={isMulti}
          onOptionChange={handleInternalOnChange}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          themeConfig={selectThemeConfig}
          caretIcon={SelectCaret}
          {...rest}
        />
      </StyledSelectWrapper>
    );
  }
);

export default SelectComponent;
