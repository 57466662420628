export const LEVEL_EASY = 'LEVEL_EASY';
export const LEVEL_MEDIUM = 'LEVEL_MEDIUM';
export const LEVEL_HARD = 'LEVEL_HARD';
export const LEVEL_SUPER = 'LEVEL_SUPER';
export const LEVEL_OPTIONS = [LEVEL_EASY, LEVEL_MEDIUM, LEVEL_HARD, LEVEL_SUPER];

export const INTENSITY_EASY = 'INTENSITY_EASY';
export const INTENSITY_MEDIUM = 'INTENSITY_MEDIUM';
export const INTENSITY_HARD = 'INTENSITY_HARD';
export const INTENSITY_SUPER = 'INTENSITY_SUPER';
export const INTENSITY_OPTIONS = [INTENSITY_EASY, INTENSITY_MEDIUM, INTENSITY_HARD, INTENSITY_SUPER];

export const EXERCISE_TYPE_EXERCISE = 'TYPE_EXERCISE';
export const EXERCISE_TYPE_MOBILITY = 'TYPE_MOBILITY';
export const EXERCISE_TYPE_STRETCH = 'TYPE_STRETCH';
export const EXERCISE_TYPE_ACTIVE_STRETCH = 'TYPE_ACTIVE_STRETCH';
export const EXERCISE_TYPE_OPTIONS = [EXERCISE_TYPE_EXERCISE, EXERCISE_TYPE_STRETCH, EXERCISE_TYPE_ACTIVE_STRETCH, EXERCISE_TYPE_MOBILITY];

export const SPACE_SITTING = 'SPACE_SITTING';
export const SPACE_SITTING_SPACE = 'SPACE_SITTING_SPACE';
export const SPACE_STANDING = 'SPACE_STANDING';
export const SPACE_STANDING_SPACE = 'SPACE_STANDING_SPACE';
export const SPACE_LYING = 'SPACE_LYING';
export const SPACE_WALL = 'SPACE_WALL';
export const SPACE_KNEELING = 'SPACE_KNEELING';
export const SPACE_OPTIONS = [SPACE_SITTING, SPACE_SITTING_SPACE, SPACE_STANDING, SPACE_STANDING_SPACE, SPACE_LYING, SPACE_WALL, SPACE_KNEELING];

export const FOCUS_NECK_HEAD = 'FOCUS_NECK_HEAD';
export const FOCUS_NERD_NECK = 'FOCUS_NERD_NECK';
export const FOCUS_ROUNDED_SHOULDERS = 'FOCUS_ROUNDED_SHOULDERS';
export const FOCUS_HUNCHBACK = 'FOCUS_HUNCHBACK';
export const FOCUS_FORWARD_HIP_TILT = 'FOCUS_FORWARD_HIP_TILT';
export const FOCUS_BACKWARD_HIP_TILT = 'FOCUS_BACKWARD_HIP_TILT';
export const FOCUS_LEGS_BUTT = 'FOCUS_LEGS_BUTT';
export const FOCUS_CARDIO = 'FOCUS_CARDIO';
export const FOCUS_SHOULDERS_ARMS = 'FOCUS_SHOULDERS_ARMS';
export const FOCUS_CHEST_BACK = 'FOCUS_CHEST_BACK';
export const FOCUS_CORE = 'FOCUS_CORE';
export const FOCUS_ALL = 'FOCUS_ALL';
export const FOCUS_OPTIONS = [FOCUS_NECK_HEAD, FOCUS_NERD_NECK, FOCUS_ROUNDED_SHOULDERS, FOCUS_HUNCHBACK, FOCUS_FORWARD_HIP_TILT, FOCUS_BACKWARD_HIP_TILT, FOCUS_LEGS_BUTT, FOCUS_CARDIO, FOCUS_SHOULDERS_ARMS, FOCUS_CHEST_BACK, FOCUS_CORE];
export const DEFAULT_FOCUS_OPTIONS = [FOCUS_NECK_HEAD, FOCUS_NERD_NECK, FOCUS_ROUNDED_SHOULDERS, FOCUS_HUNCHBACK, FOCUS_FORWARD_HIP_TILT, FOCUS_BACKWARD_HIP_TILT, FOCUS_LEGS_BUTT, FOCUS_CARDIO, FOCUS_SHOULDERS_ARMS, FOCUS_CHEST_BACK, FOCUS_CORE];

export const TARGETS_NECK = 'TARGETS_NECK';
export const TARGETS_SPINE = 'TARGETS_SPINE';
export const TARGETS_UPPER_BACK = 'TARGETS_UPPER_BACK';
export const TARGETS_MID_BACK = 'TARGETS_MID_BACK';
export const TARGETS_LOWER_BACK = 'TARGETS_LOWER_BACK';
export const TARGETS_ABS = 'TARGETS_ABS';
export const TARGETS_SIDE_BODY = 'TARGETS_SIDE_BODY';
export const TARGETS_CORE = 'TARGETS_CORE';
export const TARGETS_SHOULDERS = 'TARGETS_SHOULDERS';
export const TARGETS_CHEST = 'TARGETS_CHEST';
export const TARGETS_HIPS = 'TARGETS_HIPS';
export const TARGETS_HIP_FLEXORS = 'TARGETS_HIP_FLEXORS';
export const TARGETS_GLUTES = 'TARGETS_GLUTES';
export const TARGETS_HAMSTRINGS = 'TARGETS_HAMSTRINGS';
export const TARGETS_QUADS = 'TARGETS_QUADS';
export const TARGETS_CALVES = 'TARGETS_CALVES';
export const TARGETS_ANKLES_FEET = 'TARGETS_ANKLES_FEET';
export const TARGETS_WRISTS_HANDS = 'TARGETS_WRISTS_HANDS';
export const TARGETS_ARMS_ELBOWS = 'TARGETS_ARMS_ELBOWS';
export const TARGETS_GROIN = 'TARGETS_GROIN';
export const TARGETS_ADDUCTORS = 'TARGETS_ADDUCTORS';
export const TARGETS_ABDUCTORS = 'TARGETS_ABDUCTORS';
export const TARGETS_TRICEPS = 'TARGETS_TRICEPS';
export const TARGETS_BICEPS = 'TARGETS_BICEPS';
export const TARGETS_TRAPS = 'TARGETS_TRAPS';
export const TARGETS_OPTIONS = [TARGETS_NECK, TARGETS_SPINE, TARGETS_UPPER_BACK, TARGETS_MID_BACK, TARGETS_LOWER_BACK, TARGETS_ABS, TARGETS_SIDE_BODY, TARGETS_SIDE_BODY, TARGETS_CORE, TARGETS_SHOULDERS, TARGETS_CHEST, TARGETS_HIPS, TARGETS_HIP_FLEXORS, TARGETS_GLUTES, TARGETS_HAMSTRINGS, TARGETS_QUADS, TARGETS_CALVES, TARGETS_ANKLES_FEET, TARGETS_WRISTS_HANDS, TARGETS_ARMS_ELBOWS, TARGETS_GROIN, TARGETS_ADDUCTORS, TARGETS_ABDUCTORS, TARGETS_TRICEPS, TARGETS_BICEPS, TARGETS_TRAPS];

export const PROPS_TENNIS_BALL = 'PROPS_TENNIS_BALL';
export const PROPS_CHAIR = 'PROPS_CHAIR';
export const PROPS_EXERCISE_BAND = 'PROPS_EXERCISE_BAND';
export const PROPS_PAPER_PACK = 'PROPS_PAPER_PACK';
export const PROPS_DESK = 'PROPS_DESK';
export const PROPS_STEP = 'PROPS_STEP';
export const PROPS_STEPS = 'PROPS_STEPS';
export const PROPS_SOFA = 'PROPS_SOFA';
export const PROPS_WALL = 'PROPS_WALL';
export const PROPS_DOORWAY = 'PROPS_DOORWAY';
export const PROPS_OPTIONS = [PROPS_TENNIS_BALL, PROPS_CHAIR, PROPS_EXERCISE_BAND, PROPS_PAPER_PACK, PROPS_DESK, PROPS_STEP, PROPS_STEPS, PROPS_SOFA, PROPS_WALL, PROPS_DOORWAY];
export const DEFAULT_PROPS_OPTIONS = [PROPS_CHAIR, PROPS_WALL, PROPS_DESK, PROPS_STEP];

export const FREQUENCY_30 = 'FREQUENCY_30';
export const FREQUENCY_60 = 'FREQUENCY_60';
export const FREQUENCY_90 = 'FREQUENCY_90';
export const FREQUENCY_120 = 'FREQUENCY_120';
export const FREQUENCY_150 = 'FREQUENCY_150';
export const FREQUENCY_180 = 'FREQUENCY_180';
export const FREQUENCY_OPTIONS = [FREQUENCY_30, FREQUENCY_60, FREQUENCY_90, FREQUENCY_120, FREQUENCY_150, FREQUENCY_180];
export const FREQUENCY_TO_MINUTES = {
  FREQUENCY_30: 0.5,
  FREQUENCY_60: 60,
  FREQUENCY_90: 90,
  FREQUENCY_120: 120,
  FREQUENCY_150: 150,
  FREQUENCY_180: 180
};

export const NOTIFICATION_TYPE_PERSISTENT = 'NOTIFICATION_TYPE_PERSISTENT';
export const NOTIFICATION_TYPE_TRANSIENT = 'NOTIFICATION_TYPE_TRANSIENT';
export const NOTIFICATION_TYPE_NONE = 'NOTIFICATION_TYPE_NONE';
export const NOTIFICATION_TYPE_OPTIONS = [NOTIFICATION_TYPE_PERSISTENT, NOTIFICATION_TYPE_TRANSIENT, NOTIFICATION_TYPE_NONE];

export const WORKOUT_EXERCISE_TYPE_TIME = 'WORKOUT_EXERCISE_TYPE_TIME';
export const WORKOUT_EXERCISE_TYPE_REPS = 'WORKOUT_EXERCISE_TYPE_REPS';
export const WORKOUT_EXERCISE_TYPE_OPTIONS = [WORKOUT_EXERCISE_TYPE_TIME, WORKOUT_EXERCISE_TYPE_REPS];

export const WORKOUT_EXERCISE_DEFAULT_LOBBY_IN_SECONDS = 7;

export const FIXED_TYPE_COLORS = {
  FOCUS_NECK_HEAD: 'accent-1',
  FOCUS_NERD_NECK: 'accent-2',
  FOCUS_ROUNDED_SHOULDERS: 'accent-3',
  FOCUS_HUNCHBACK: 'accent-4',
  FOCUS_FORWARD_HIP_TILT: 'accent-5',
  FOCUS_BACKWARD_HIP_TILT: 'brand',
  FOCUS_LEGS_BUTT: 'accent-1',
  FOCUS_CARDIO: 'accent-2',
  FOCUS_SHOULDERS_ARMS: 'accent-3',
  FOCUS_CHEST_BACK: 'accent-4',
  FOCUS_CORE: 'accent-5',
  FOCUS_ALL: 'brand',
  INTENSITY_EASY: 'accent-1',
  INTENSITY_MEDIUM: 'accent-2',
  INTENSITY_HARD: 'accent-3',
  INTENSITY_SUPER: 'accent-4',
  LEVEL_EASY: 'accent-5',
  LEVEL_MEDIUM: 'accent-1',
  LEVEL_HARD: 'accent-2',
  LEVEL_SUPER: 'accent-3',
  [EXERCISE_TYPE_EXERCISE]: 'accent-1',
  [EXERCISE_TYPE_MOBILITY]: 'accent-5',
  [EXERCISE_TYPE_STRETCH]: 'accent-3',
  [EXERCISE_TYPE_ACTIVE_STRETCH]: 'accent-4',
};
