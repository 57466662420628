import React, { forwardRef, useState } from 'react'
import { StyledCheckboxContainer, StyledCheckbox, StyledHiddenCheckbox, StyledIcon } from 'shared-ui/src/components/ui/Checkbox/styles';
import { convertBoolToCheckboxValue, convertCheckboxValueToBool } from './utils';
import { StyledCheckboxContainerLabel, StyledCheckboxLabel } from './styles';

const CheckboxComponent = forwardRef(
  (
    {
      onChange,
      onBlur,
      disabled,
      width,
      name,
      height,
      value,
      defaultValue,
      labelRight,
      labelLeft,
      className
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(defaultValue);
    const handleInternalOnChange = e => {
      if(value !== undefined) {
        onChange(convertBoolToCheckboxValue(e.target.checked));
      } else {
        setInternalValue(convertBoolToCheckboxValue(e.target.checked));
        onChange();
      }
    };
    const handleInternalOnBlur = e => onBlur();
    const handleOnBlur = value !== undefined ? onBlur : handleInternalOnBlur;
    const val = value !== undefined ? value : internalValue;

    const attrs = {
      labelLeft: (labelLeft),
      labelRight: (labelRight)
    };

    return (
      <StyledCheckboxContainerLabel htmlFor={name}>
        <StyledCheckboxContainer className={className}>
          <StyledHiddenCheckbox
            type="checkbox"
            value={true}
            checked={convertCheckboxValueToBool(val)}
            onChange={handleInternalOnChange}
            onBlur={handleOnBlur}
            id={name}
            ref={ref}
          />
          {labelLeft && <StyledCheckboxLabel attrs={attrs}>{labelLeft}</StyledCheckboxLabel>}
          <StyledCheckbox checked={convertCheckboxValueToBool(val)}>
            <StyledIcon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </StyledIcon>
          </StyledCheckbox>
          {labelRight && <StyledCheckboxLabel attrs={attrs}>{labelRight}</StyledCheckboxLabel>}
        </StyledCheckboxContainer>
      </StyledCheckboxContainerLabel>
    );
  }
);

export default CheckboxComponent;
