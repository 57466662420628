import styled, { css } from 'styled-components';
import { removePx } from '../../../utils/strings';
import { transition } from '../../../utils/mixins';

export const StyledCheckboxContainerLabel = styled.label`
  display: inline-block;
`;

export const StyledCheckboxLabel = styled.span`
  ${props => props.attrs.labelRight && css `margin-left: ${props => props.theme.global.spacing.xs};`}
  ${props => props.attrs.labelLeft && css `margin-right: ${props => props.theme.global.spacing.xs};`}
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledIcon = styled.svg`
  fill: none;
  stroke: white;
  top: -2px;
  position: relative;
  stroke-width: ${({theme: { checkbox: { arrowStrokeWidth }}}) => removePx(arrowStrokeWidth)}px;
`;

export const StyledHiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const checkboxProps = ({checked: checkedAttr, theme: { checkbox }}) => {
  const { width, borderRadius, borderWidth, focusShadowWidth, height, checked, unchecked } = checkbox;
  const checkStyles = (checkedAttr) ? checked : unchecked;

  return css`
    border-radius: ${borderRadius};
    border-width: ${borderWidth};
    width: ${removePx(width)}px;
    height: ${removePx(height)}px;
    border-color: ${checkStyles.borderColor};
    background: ${checkStyles.background};
    
    ${StyledHiddenCheckbox}:focus + & {
      box-shadow: 0 0 0 ${removePx(focusShadowWidth)}px ${checkStyles.focusShadowColor};
    }
  `;
};

export const StyledCheckbox = styled.div`
  border-style: solid;
  display: inline-block;
  ${props => checkboxProps(props)}
  ${({theme}) => theme.checkbox.transition && transition(theme.checkbox.transition)}

  ${StyledIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;
