import React, { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withFirebase } from 'react-recoil-firebase';
import {
  StyledWorkoutBuilder,
  StyledWorkoutBuilderForm,
  StyledWorkoutBuilderFormExercise,
  StyledWorkoutBuilderFormFocus
} from './styles';
import isEqual from 'lodash/isEqual';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ExerciseCard } from './components/ExerciseCard';
import { asyncForEach } from '../../../utils/arrays';
import { Button, Heading, Loader } from 'shared-ui';
import Select from 'shared-ui/src/components/ui/Select';
import { FOCUS_OPTIONS } from 'constants/fixedTypes';
import { getMessageString } from '../../../components/FixedTypeMessage/utils';
import { useCollectionData } from 'react-recoil-firebase/src/hooks/firestore/useCollection';
import { addUpdateWorkout } from '../WorkoutForm/database';
import { useRecoilValue } from 'recoil';
import { authDataState } from 'react-recoil-firebase/src/atoms/auth';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = isDraggingOver => ({
  //background: isDraggingOver ? "lightblue" : "lightgrey",
  //padding: grid,
  //width: 250
});

const WorkoutBuilder = ({workout, firebase, onSave}) => {
  const mounted = useRef(false);
  if(!workout) return null;
  const { user: { uid: userId } } = useRecoilValue(authDataState) || {};

  const [exercises, setExercises] = useState(null);
  const [selectedAddExercise, setSelectedAddExercise] = useState(null);
  const [selectedAddExerciseFocus, setSelectedAddExerciseFocus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addExerciseOptionsFiltered, setAddExerciseOptionsFiltered] = useState([]);
  const { data: addExerciseOptions, loading: addExerciseOptionsLoading } = useCollectionData('exercises',
    firebase.firestore().collection('Exercises')
  );

  useEffect(() => {
    if(!mounted.current) {
      mounted.current = true;
      async function fetchData() {
        const fetchedExercises = [];
        try {
          if(workout && workout.exercises && workout.exercises.length) {
            await asyncForEach(workout.exercises, async (exercise) => {
              const workoutExercise = await firebase.firestore().collection('Exercises').doc(exercise.exerciseId).get();
              if(workoutExercise.exists) {
                fetchedExercises.push({...exercise, data: workoutExercise.data(), key: uuidv4()});
              }
            });
          }
          return Promise.resolve(fetchedExercises);
        } catch (e) {
          return Promise.reject(e);
        }
      }

      fetchData().then(data => {
        setExercises(data);
        setLoading(false);
      }).catch((e) => {
        console.log(e);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if(addExerciseOptions && addExerciseOptions.length) {
      setAddExerciseOptionsFiltered(addExerciseOptions
        .filter(item => (selectedAddExerciseFocus && selectedAddExerciseFocus.length) ? selectedAddExerciseFocus.every(option => item.focus && item.focus.includes(option)) : true)
        .map((e) => ({
          label: e.name,
          value: e
        })));
    }
  }, [addExerciseOptions, selectedAddExerciseFocus]);

  const handleAddExercise = useCallback(() => {
    const newExercise = { data: selectedAddExercise, exerciseId: selectedAddExercise.id, key: uuidv4() };
    setExercises([...exercises, newExercise]);
    setSelectedAddExerciseFocus([]);
    setSelectedAddExercise(null);
  }, [selectedAddExercise]);

  const handleDeleteExercise = useCallback(async (exerciseId, callback = () => null) => {
    const newExercises = exercises.filter(e => e.exerciseId !== exerciseId);
    setLoading(true);

    try {
      await addUpdateWorkout(firebase, userId, workout.id, { exercises: newExercises});
      setExercises(newExercises);
      setLoading(false);
      callback();
    } catch (e) {
      alert('Error!');
      setLoading(false);
    }
  },[exercises]);

  const handleSaveExercises = useCallback(async (exercise, callback = () => null) => {
    const newExercises = exercises && exercises.length ? exercises.map(e => e.exerciseId === exercise.exerciseId ? exercise : e) : [exercise];
    setLoading(true);
    try {
      await addUpdateWorkout(firebase, userId, workout.id, { exercises: newExercises });
      setExercises(newExercises);
      setLoading(false);
      callback();
    } catch (e) {
      alert('Error!');
      setLoading(false);
    }
  }, [exercises]);

  const onDragEnd = useCallback( (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      exercises,
      result.source.index,
      result.destination.index
    );

    addUpdateWorkout(firebase, userId, workout.id, { exercises: items});

    setExercises(items);
  }, [exercises]);

  const isLoading = (loading || addExerciseOptionsLoading);

  return (
    <StyledWorkoutBuilder>
      {isLoading && <Loader/>}

      {!isLoading && (
        <StyledWorkoutBuilderForm>
          <Heading level={3}>Workout Builder</Heading>
          <StyledWorkoutBuilderFormFocus>
            <Select
              value={selectedAddExerciseFocus}
              onChange={val => {
                console.log(val);
                setSelectedAddExerciseFocus(val)
              }}
              options={FOCUS_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
              size="sm"
              placeholder="Filter exercises by focus"
              isMulti={true}
            />
          </StyledWorkoutBuilderFormFocus>
          <StyledWorkoutBuilderFormExercise>
            <Select
              value={selectedAddExercise}
              onChange={val => setSelectedAddExercise(val)}
              options={addExerciseOptionsFiltered}
              size="sm"
              placeholder="Exercises"
              isMulti={false}
            />
            <Button size="sm" type="accent-1" disabled={(!selectedAddExercise)} onClick={handleAddExercise}>Add</Button>
          </StyledWorkoutBuilderFormExercise>
        </StyledWorkoutBuilderForm>
      )}

      {(exercises !== undefined && exercises !== null && exercises.length > 0) && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {exercises.map((item, index) => (
                  <Draggable key={item.key} draggableId={item.key} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ExerciseCard exercise={item} onSave={handleSaveExercises} onDelete={handleDeleteExercise}/>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </StyledWorkoutBuilder>
  );
};

/*
style={getItemStyle(
  snapshot.isDragging,
  provided.draggableProps.style
)}
 */

export default withFirebase(WorkoutBuilder);
