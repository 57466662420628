import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { withFirebase } from 'react-recoil-firebase';
import { Alert } from 'shared-ui';
import { Dropzone } from 'shared-ui/dropzone';
import { useRecoilValue } from 'recoil';
import { authDataState } from 'react-recoil-firebase/src/atoms/auth';
import { useDownloadUrl } from 'react-recoil-firebase/src/hooks/storage/useDownloadURL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-pro-regular/faTimes';
import { faLink } from '@fortawesome/fontawesome-pro-regular/faLink';
import {
  StyledUploadedImage,
  StyledUploadedImageDelete,
  StyledUploadedImageImg,
  StyledUploadedImages,
  StyledUploadedImageView
} from './styles';

const UploadedImage = ({image: { path, file, status }, firebase, onDelete}) => {
  const { data, loading, error } = useDownloadUrl(path, firebase.storage().ref().child(path));
  if(error) return null;
  return (
    <StyledUploadedImage>
      {data && (
        <>
          <StyledUploadedImageView onClick={(e) => {
            e.stopPropagation();
            window.open(data,'_blank');
          }}>
            <FontAwesomeIcon icon={faLink}/>
          </StyledUploadedImageView>
          <StyledUploadedImageDelete onClick={(e) => {
            e.stopPropagation();
            onDelete(path);
          }}>
            <FontAwesomeIcon icon={faTimes}/>
          </StyledUploadedImageDelete>
          <StyledUploadedImageImg src={data} />
        </>
      )}
    </StyledUploadedImage>
  );
};

const ExerciseImagesUpload = ({exercise, firebase, onSave, ...rest}) => {
  const storageRef = firebase.storage().ref();
  const { id: exerciseId, imageReferences } = exercise || {};
  const { user: { uid: userId } } = useRecoilValue(authDataState) || {};
  const [prevImageReferences, setPrevImageReferences] = useState(imageReferences);
  const [images, setImages] = useState((imageReferences && imageReferences.length) ? imageReferences.map(path => ({status: 'uploaded', path})) : []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(!isEqual(prevImageReferences, imageReferences)) {
      setPrevImageReferences(imageReferences);
      setImages(imageReferences.map(img => ({status: 'uploaded', path: img})));
    }
  }, [imageReferences]);

  const handleImageUpload = async (acceptedFiles) => {
    setImages([...images, ...acceptedFiles.map(file => ({status: 'uploading', file}))]);
    setLoading(true);

    for (const acceptedFile of acceptedFiles) {
      try {
        const imageRef = storageRef.child(`exercises/${exerciseId}/images/${acceptedFile.name}`);
        await imageRef.put(acceptedFile);
        await firebase.firestore().doc(`Exercises/${exerciseId}`).update({imageReferences: firebase.firestore.FieldValue.arrayUnion(imageRef.fullPath)});
        setLoading(false);
        setError(null);
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  const handleImageDelete = async (imagePath) => {
    const imageRef = storageRef.child(imagePath);
    setLoading(true);
    try {
      await imageRef.delete();
      await firebase.firestore().doc(`Exercises/${exerciseId}`).update({imageReferences: firebase.firestore.FieldValue.arrayRemove(imageRef.fullPath)});
      setLoading(false);
      setError(null);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <div>
      {error && <Alert type="error" message={error}/>}
      <Dropzone onDrop={handleImageUpload} loading={loading} label="Exercise Images (For Reference)">
        {(images && images.length > 0) && (
          <StyledUploadedImages>
            {images.map((image, i) => (
              <>
                {(image.path && image.status === 'uploaded') && (
                  <UploadedImage
                    firebase={firebase}
                    image={image}
                    key={i}
                    onDelete={handleImageDelete}
                  />
                )}

                {(image.file && image.status === 'uploading') && (
                  <StyledUploadedImage style={{opacity: 0.5}}>
                    <StyledUploadedImageImg src={URL.createObjectURL(image.file)} />
                  </StyledUploadedImage>
                )}

              </>
            ))}
          </StyledUploadedImages>
        )}
      </Dropzone>
    </div>
  );
};

export default withFirebase(ExerciseImagesUpload);
