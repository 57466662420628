import React from 'react';
import { getMessageString } from 'web-app/src/components/FixedTypeMessage/utils';

const FixedTypeMessage = ({ message }) => {
  //const { formatMessage } = useIntl();
  //return getMessageString(message, formatMessage);

  return getMessageString(message);
};

export default FixedTypeMessage;
