import { trim } from 'lodash';
import { capitalize } from 'web-app/src/utils/strings';
import messages from 'web-app/src/components/FixedTypeMessage/messages';

export const getFriendlyFixedTypeName = (FEATURE_IN_CAPS, onlyEndItem = false, textTransform = 'capitalize') => {
  let friendly = '';
  if(onlyEndItem) {
    const featureItems = FEATURE_IN_CAPS.split('_');
    friendly = (featureItems[featureItems.length - 1] !== undefined) ? featureItems[featureItems.length - 1].toLowerCase() : '';
  } else {
    friendly = FEATURE_IN_CAPS.replace(/_/g, ' ').toLowerCase();
  }

  if(textTransform === 'capitalize') {
    friendly = capitalize(friendly);
  }

  return trim(friendly);
};

export const getMessageString = (message) => {
  if(!message) return '';
  message = (message.includes('|')) ? message.split('|')[0] : message;
  return (messages[message]) ? messages[message].defaultMessage : getFriendlyFixedTypeName(message);
};

/*
// FOR REACT INTL ---

export const getMessageString = (message, formatMessage) => {
  if(!message) return '';
  message = (message.includes('|')) ? message.split('|')[0] : message;
  return (messages[message]) ? formatMessage(messages[message]) : getFriendlyFixedTypeName(message);
};*/
