import ExercisesPage from 'web-app/src/routes/admin/ExercisesPage';
import { appAdminRoute, baseRoutePaths } from '../../../constants/routes';
import WorkoutsPage from '../../../routes/admin/WorkoutsPage';
import WorkoutPage from '../../../routes/admin/WorkoutPage';
import WorkoutPreviewPage from '../../../routes/admin/WorkoutPreviewPage';

export const routes = {
  EXERCISES: {
    path: `${appAdminRoute(baseRoutePaths.EXERCISES)}`,
    component: ExercisesPage,
    privateRoute: true,
    adminRoute: true
  },
  EXERCISE: {
    path: `${appAdminRoute(baseRoutePaths.EXERCISES)}/:exerciseId`,
    component: ExercisesPage,
    privateRoute: true,
    adminRoute: true
  },
  WORKOUTS: {
    path: `${appAdminRoute(baseRoutePaths.WORKOUTS)}`,
    component: WorkoutsPage,
    privateRoute: true,
    adminRoute: true
  },
  WORKOUT: {
    path: `${appAdminRoute(baseRoutePaths.WORKOUTS)}/:workoutId`,
    component: WorkoutPage,
    privateRoute: true,
    adminRoute: true
  },
  WORKOUT_PREVIEW: {
    path: `${appAdminRoute(`${baseRoutePaths.WORKOUTS}/${baseRoutePaths.WORKOUT_PREVIEW}`)}/:workoutId`,
    component: WorkoutPreviewPage,
    privateRoute: true,
    adminRoute: true
  }
};
