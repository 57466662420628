import React from 'react';
import { Container, Box, Heading, Loader, Button } from 'shared-ui';
import { useDocumentData } from 'react-recoil-firebase/src/hooks/firestore/useDocument';
import WorkoutForm from '../../../containers/admin/WorkoutForm';
import { withFirebase } from 'react-recoil-firebase';
import { navigate, useParams } from '@reach/router';
import { appAdminRoute } from '../../../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/fontawesome-pro-regular/faLongArrowLeft';
import { faSearch } from '@fortawesome/fontawesome-pro-regular/faSearch';

const Workout = ({workout}) => (
  <Container>
    <Box padding={{top: 'lg', bottom: 'lg'}}>
      <Box padding={{bottom: 'sm'}}>
        <Button spacing={{right: 'sm'}} type="brand" size="sm" onClick={() => navigate(appAdminRoute(`workouts`))} icon={<FontAwesomeIcon icon={faLongArrowLeft}/>}>Back to workouts</Button>
        <Button type="accent-5" size="sm" onClick={() => navigate(appAdminRoute(`workouts/preview/${workout.id}`))} icon={<FontAwesomeIcon icon={faSearch}/>}>Preview</Button>
      </Box>

      <WorkoutForm workout={workout} onSave={() => navigate(appAdminRoute(`workouts`))}/>
    </Box>
  </Container>
);

const WorkoutPage = ({firebase}) => {
  const { workoutId: paramsWorkoutId } = useParams();

  if(paramsWorkoutId === 'new') {
    return <Workout workout={null}/>;
  }

  const { data: workout, loading: workoutLoading, error: workoutError } = useDocumentData(`workouts/${paramsWorkoutId}`, firebase.firestore().doc(`workouts/${paramsWorkoutId}`));

  if (workoutLoading) {
    return <Loader overlay={false} fixed={true}/>;
  }

  if (workoutError) {
    return workoutError;
  }

  if (!workout) {
    return 'No data!';
  }

  return <Workout workout={workout}/>;
};

export default withFirebase(WorkoutPage);
