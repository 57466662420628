import { sanitizeValuesForDatabase } from './utils';

export const addUpdateExercise = async (firebase, userId, exerciseId, values) => {
  const data = sanitizeValuesForDatabase({ ...values, latestEditor: userId });
  try {
    const id = (exerciseId) ? await firebase.firestore().doc(`Exercises/${exerciseId}`).update(data) : await firebase.firestore().collection(`Exercises`).add(data);
    return Promise.resolve(id);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteExercise = (firebase, userId, exerciseId) => {
  if(!exerciseId) return Promise.reject('No exercise exists!');
  return firebase.firestore().doc(`Exercises/${exerciseId}`).delete().then((exerciseId) => Promise.resolve()).catch((e) => Promise.reject(e));
};
