import { sanitizeValuesForDatabase } from './utils';

export const addUpdateWorkout = async (firebase, userId, workoutId, values) => {
  if(values && values.exercises && values.exercises.length) {
    values.exercises = values.exercises.map(({data, key, ...rest}) => rest);
  }

  const sanitizedData = sanitizeValuesForDatabase({ ...values, latestEditor: userId });

  try {
    const id = (workoutId) ? await firebase.firestore().doc(`workouts/${workoutId}`).update(sanitizedData) : await firebase.firestore().collection(`workouts`).add(sanitizedData);
    return Promise.resolve(id);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteWorkout = (firebase, userId, workoutId) => {
  if(!workoutId) return Promise.reject('No workout exists!');
  return firebase.firestore().doc(`workouts/${workoutId}`).delete().then((workoutId) => Promise.resolve()).catch((e) => Promise.reject(e));
};
