import React from 'react';
import { Container, Box, Heading } from 'shared-ui';
import WorkoutsTable from 'web-app/src/containers/admin/WorkoutsTable';

const WorkoutsPage = () => {
  return (
    <Box spacing={{top: 'base'}}>
      <Container type="fluid">
        <Heading>Workouts</Heading>
        <WorkoutsTable/>
      </Container>
    </Box>
  );
};

export default WorkoutsPage;
