export const sanitizeValuesForDatabase = ({id, ...values}) => {
  const sanitizedValues = {};
  for (let [key, value] of Object.entries(values)) {
    if(key === 'published') {
      value = (value !== undefined && value !== null && value !== '');
    }
    sanitizedValues[key] = value;
  }
  return sanitizedValues;
};
