import React, { useRef, useState, useCallback } from 'react'
import {
  StyledExerciseCard, StyledExerciseCardBody,
  StyledExerciseCardHeader,
  StyledExerciseCardHeaderTitle,
  StyledExerciseCardHeaderToggle
} from './styles';
import { Button } from 'shared-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/fontawesome-pro-regular/faChevronUp';
import { faChevronDown } from '@fortawesome/fontawesome-pro-regular/faChevronDown';
import { faBars } from '@fortawesome/fontawesome-pro-regular/faBars';
import ExerciseForm from '../ExerciseForm';

export const ExerciseCard = ({ exercise, onDelete, onSave }) => {
  const { exerciseId, data } = exercise;
  const ref = useRef(null);
  const [cardOpened, setCardOpened] = useState(false);

  return (
    <StyledExerciseCard attrs={{cardOpened}}>
      <StyledExerciseCardHeader>
        <StyledExerciseCardHeaderTitle>
          <FontAwesomeIcon icon={faBars}/>
          <div onClick={() => setCardOpened(!cardOpened)}>
            <span>{data.name}</span>
            <Button
              size="sm"
              type="accent-1"
              shape="circle"
              icon={<FontAwesomeIcon icon={(cardOpened) ? faChevronUp : faChevronDown}/>}
            />
          </div>
          {exercise && exercise.type === undefined && <div className="status">(unsaved)</div>}
        </StyledExerciseCardHeaderTitle>
      </StyledExerciseCardHeader>

      {cardOpened && (
        <StyledExerciseCardBody>
          <ExerciseForm exercise={exercise} onSave={onSave} onDelete={onDelete} setCardOpened={setCardOpened}/>
        </StyledExerciseCardBody>
      )}
    </StyledExerciseCard>
  )
};
