import Table from './component';
import { withTheme } from 'styled-components';

Table.defaultProps = {

};

Table.propTypes = {

};

export default withTheme(Table);
