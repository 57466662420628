import React from 'react';
import styled, { css } from 'styled-components';
import { deepMerge } from '../../../utils/objects';
import { sanitizePropsWithBase } from '../../../utils/utils';

export const applyThemeStyles = (theme, { size, multiLabelsWrapped }) => {
  const {mode, global: { controls }, select: { styles, theme: selectTheme }} = theme;
  const themeStyles = deepMerge(styles, selectTheme);
  const sizedControls = controls.size[sanitizePropsWithBase(size)];

  themeStyles.control = {
    ...themeStyles.control,
    minHeight: sizedControls.height,
    borderWidth: sizedControls.borderWidth,
    borderStyle: sizedControls.borderStyle,
    borderRadius: sizedControls.borderRadius,
    boxShadow: '0 0 0 0.2rem',
    padding: `0.375rem ${sizedControls.horizontalPadding}`,
    transition: 'box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out',
    css: `
      background:white;
      font-size:${sizedControls.fontSize};
      fontWeight:${sizedControls.fontWeight};
      color:${themeStyles.input.color};
    `
  };

  themeStyles.input = {
    ...themeStyles.input,
    css: `
      color:${themeStyles.input.color};
    `
  };

  themeStyles.menu = {
    ...themeStyles.menu,
    css: `
      color: ${themeStyles.menu.color};
    `
  };

  themeStyles.multiValue = {
    ...themeStyles.multiValue,
    margin: (multiLabelsWrapped) ? '0px 2px 1px 0' : '0px 2px 1px 0',
    css: `
      position:relative;
      top:0.5px;
    `,
    label: {
      padding: '0px 2px 0px 6px',
    }
  };

  return themeStyles;
};

export const StyledSelectWrapper = styled.div`
  position: relative;
  width:100%;
  
  .rfs-option {
    &:hover {
      cursor: pointer;
    }
  }
  
  .rfs-control-container {
    > div {
      ${({attrs}) => !attrs.multiLabelsWrapped && css`flex-wrap: nowrap;`}
    }
  }
  
  .rfs-menu-container {
    overflow: hidden;
  }
  
  .rfs-control-container {
    &:hover {
      cursor: pointer;
    }
    > div {
       &:first-child {
        padding-right: 0;
       }
      
       &:last-child {
          > div {
            &:first-child {
              padding-right: 0;
            }
            &:nth-last-child(2) {
              display: none;
            }
          }
       }
    }
  }
`;
