import PropTypes from 'prop-types';
import Select from './component';
import { defaultPropTypes, elevationPropType, sizePropType } from '../propTypes';
import { withTheme } from 'styled-components';
import { find } from '../../../utils/arrays';
import isEqual from 'lodash/isEqual';

Select.defaultProps = {
  disabled: false,
  loading: false,
  size: 'md',
  elevation: 'md', // TODO: Not implemented yet
  className: '',
  defaultValue: '',
  placeholder: 'Select option',
  wrapperStyle: {},
  isClearable: true,
  isMulti: false,
  multiLabelsWrapped: true,
  acceptValueOnly: true,
  options: [],
  onChange: () => null,
  onFocus: () => null,
  onBlur: () => null,
  getOptionValue: (option) => (option && option.value) ? option.value : null,
  //getOptionByValue: (options, value) => find(options, (option) => option.value === value)
  getOptionByValue: (options, value) => find(options, (option) => isEqual(option.value, value))
};

Select.propTypes = {
  acceptValueOnly: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: sizePropType,
  elevation: elevationPropType,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  multiLabelsWrapped: PropTypes.bool,
  onFocus: PropTypes.func,
  options: PropTypes.array,
  onBlur: PropTypes.func,
  wrapperStyle: PropTypes.object,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  ...defaultPropTypes
};

export default withTheme(Select);
