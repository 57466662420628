import React, { useState, useEffect, useCallback } from 'react';
import { withFirebase } from 'react-recoil-firebase';
import { Alert, Button, Heading, Box, Text } from 'shared-ui';
import { Form } from 'shared-ui/form';
import { FormItem } from 'shared-ui/formItem';
import { Input } from 'shared-ui/input';
import { Select } from 'shared-ui/select';
import { Checkbox } from 'shared-ui/checkbox';
import { hasErrors } from 'shared-ui/src/utils/form';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  WORKOUT_EXERCISE_TYPE_OPTIONS, WORKOUT_EXERCISE_TYPE_REPS, WORKOUT_EXERCISE_TYPE_TIME, WORKOUT_EXERCISE_DEFAULT_LOBBY_IN_SECONDS
} from 'constants/fixedTypes';
import { getMessageString } from '../../../../../components/FixedTypeMessage/utils';
import { StyledFormActions } from './styles';
import { Grid, Cell } from 'shared-ui/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/fontawesome-pro-regular/faPlus';
import { faTimes } from '@fortawesome/fontawesome-pro-regular/faTimes';
import { withResponsive } from 'shared-ui/src/contexts/responsive';

const ExerciseForm = ({exercise, onSave, onDelete, responsive, setCardOpened}) => {
  console.log(exercise);
  const { exerciseId, data } = exercise || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const form = useForm({defaultValues: exercise});
  const { errors, reset, watch, control, getValues, trigger, clearErrors } = form;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'instructions'
  });

  const onSubmit = useCallback( async () => {
    const result = await trigger();
    if(result) {
      const formData = getValues();
      onSave({...exercise, ...formData}, () => setCardOpened(false));
    } else {
      setError((errors.instructions) ? 'You have an error in your instructions. Probably an empty field' : 'Woops, there has been an error. Please check your inputs');
      clearErrors();
    }
  }, [errors]);

  const type = watch('type', false);

  return (
    <Form form={form}>
      {error && <Alert type="error" message={error} closable onClose={() => setError(null)}/>}

      <Heading level={4} spacing={{bottom: 'none'}}>{data.name}</Heading>
      <Text size="sm" as="p" strength="xweak" text={`Time key can a number (in seconds) or "start", "middle", "ending" & "complete"`} spacing={{bottom: 'sm'}}/>

      <Grid columns="repeat(auto-fit,minmax(320px,1fr))" spacing={{bottom: 'base'}}>
        <Cell>
          <FormItem
            style={{marginBottom: 0}}
            name="type"
            label={'Type'}
            rules={{
              required: true
            }}
            render={props => (
              <Select
                options={WORKOUT_EXERCISE_TYPE_OPTIONS.map(o => ({value: o, label: getMessageString(o)}))}
                size="sm"
                placeholder="Type"
                {...props}
              />
            )}
          />
        </Cell>

        {(type !== undefined) && (
          <Cell>
            {type === WORKOUT_EXERCISE_TYPE_TIME ? (
              <FormItem
                style={{marginBottom: 0}}
                name="duration"
                label={'Duration (seconds)'}
                render={props => (
                  <Input
                    size="sm"
                    htmlType="number"
                    placeholder="Duration in seconds"
                    showPlaceholderOnFocus={false}
                    {...props}
                  />
                )}
              />
            ) : type === WORKOUT_EXERCISE_TYPE_REPS ? (
              <FormItem
                style={{marginBottom: 0}}
                name="reps"
                label={'Reps'}
                render={props => (
                  <Input
                    size="sm"
                    htmlType="number"
                    placeholder="Number of reps"
                    showPlaceholderOnFocus={false}
                    {...props}
                  />
                )}
              />
            ) : null }
          </Cell>
        )}

        <Cell>
          <FormItem
            style={{marginBottom: 0}}
            name="lobbyInSeconds"
            label={'Lobby time (seconds)'}
            render={props => (
              <Input
                size="sm"
                htmlType="number"
                placeholder={`Defaults to ${WORKOUT_EXERCISE_DEFAULT_LOBBY_IN_SECONDS} seconds`}
                showPlaceholderOnFocus={false}
                {...props}
              />
            )}
          />
        </Cell>
      </Grid>

      <Heading level={4} spacing={{bottom: 'sm'}}>Instructions</Heading>

      {fields.map((item, index) => (
        <Grid gap="sm" columns={responsive !== 'sm' ? `200px 1fr 100px 40px` : `1fr`} spacing={{bottom: 'sm'}} key={index}>
          <Cell>
            <FormItem
              style={{marginBottom: 0}}
              name={`instructions[${index}].timeKey`}
              label={'Time key'}
              rules={{
                required: true
              }}
              render={props => (
                <Input
                  size="sm"
                  placeholder="Time key"
                  showPlaceholderOnFocus={false}
                  {...props}
                />
              )}
            />
          </Cell>

          <Cell>
            <FormItem
              style={{marginBottom: 0}}
              name={`instructions[${index}].text`}
              label={'Instruction'}
              rules={{
                required: true
              }}
              render={props => (
                <Input
                  size="sm"
                  placeholder="Instruction"
                  showPlaceholderOnFocus={false}
                  {...props}
                />
              )}
            />
          </Cell>

          <Cell middle>
            <FormItem
              style={{marginBottom: 0}}
              name={`instructions[${index}].silent`}
              label={'Silent'}
              render={props => (
                <Checkbox
                  size="md"
                  labelRight="Is silent?"
                  {...props}
                />
              )}
            />
          </Cell>

          <Cell style={{display: 'flex', alignItems: 'flex-end'}}>
            <Button type="brand" size="sm" icon={<FontAwesomeIcon icon={faTimes}/>} onClick={() => remove(index)}/>
          </Cell>
        </Grid>
      ))}

      <Button type="default" size="sm" icon={<FontAwesomeIcon icon={faPlus}/>} onClick={() => append({key: '', value: ''})}/>

      <StyledFormActions>
        <Button
          disabled={hasErrors(errors)}
          //htmlType="submit"
          type="brand"
          size="sm"
          onClick={onSubmit}
          loading={loading}
        >
          Save Changes
        </Button>

        {exerciseId && <Button loading={loading} size="sm" onClick={() => onDelete(exerciseId, () => setCardOpened(false))}>Delete Exercise</Button>}
      </StyledFormActions>
    </Form>
  );
};

export default withFirebase(withResponsive(ExerciseForm));
