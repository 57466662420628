import React from 'react';
import styled, { css } from 'styled-components';
import { transition } from '../../../utils/mixins';
import { globalStyles } from '../../../themes/base.plain';
import { StyledFormItemLabel } from '../FormItem/styles';

const dropzoneStyles = ({isDragAccept, isDragReject, loading, isDragActive, theme: { dropzone }}) => {

  const { style, accept, reject, active, hover, resting } = dropzone;
  const { padding, fontSize, borderWidth, borderStyle, borderRadius } = style;
  const canHover = (!loading && !isDragAccept && !isDragReject && !isDragActive);

  let colorStyles = resting;
  if(isDragAccept) colorStyles = accept;
  if(isDragReject) colorStyles = reject;
  if(isDragActive) colorStyles = active;

  return css`
    padding: ${padding};
    font-size: ${fontSize};
    border-width: ${borderWidth};
    border-radius: ${borderRadius};
    border-style: ${borderStyle};
   
    background: ${colorStyles.background};
    border-color: ${colorStyles.borderColor};
    color: ${colorStyles.color};
    
    ${transition(dropzone.transition)}
    
    ${canHover && css`
      &:hover {
        cursor:pointer;
        background: ${hover.background};
        border-color: ${hover.borderColor};
        color: ${hover.color};
      }
    `}
  `;
};

export const StyledDropzoneContainer = styled.div`
  //flex: 1;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  position: relative;
  outline: none;
  ${props => dropzoneStyles(props)};
`;

export const StyledDropzoneLabel = styled(StyledFormItemLabel)`
  
`;

export const StyledDropzone = styled.section`
  
`;

export const StyledDropzoneItems = styled.div`
  
`;
