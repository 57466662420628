import React from 'react';
import { Container, Box, ThemeModeToggle, Heading } from 'shared-ui';
import ExercisesTable from 'web-app/src/containers/admin/ExercisesTable';

const ExercisesPage = () => {
  return (
    <Box spacing={{top: 'base'}}>
      <Container type="fluid">
        <Heading>Exercises</Heading>
        <ExercisesTable/>
      </Container>
    </Box>
  );
};

export default ExercisesPage;
